import Vue from 'vue'

import { mapGetters } from 'vuex'
import logError from '~/utils/log-error'
import { gbViewedExperimentsParamName } from '~/lib/fastly-constants'

let growthbookEventPushed = false

function getLanguageCode (code) {
  return ({
    fr: 'fr-FR'
  })[code] ?? code
}

function gtmReturnId (item) {
  const sizecup = item.sku.slice(6).split('x')
  const s = sizecup[0].length === 2 ? '00' + sizecup[0] : '0' + sizecup[0]
  const customid = sizecup[1] ? item._product.sku + sizecup[1] + ' ' + s : item._product.sku + sizecup[0]
  return customid
}

function gtmReturnIdProduct (product, selectedSize) {
  const sizecup = selectedSize.split(' / ')
  let customidproduct = product.sku + sizecup[0]
  if (sizecup[1]) {
    customidproduct += ' ' + sizecup[1].padStart(4, '0')
  }
  return customidproduct
}

function gtmReturnVariantSku (sku, product) {
  if (product?.isBundle || product?._product?.isBundle) {
    return null
  } else {
    return sku.slice(-2)
  }
}

Vue.mixin({
  computed: {
    ...mapGetters({
      market: 'frontend/market',
      pricelist: 'frontend/pricelist',
      currentCountry: 'frontend/currentCountry',
      currentLanguageCode: 'frontend/currentLanguageCode',
      currentCountryCode: 'frontend/currentCountryCode',
      countrySpecificInformation: 'storyblok/getCountrySpecificInformation'
    })
  },
  mounted () {
    // Lazy init of the dataLayer that the GTM initialize will use
    window.dataLayer = window.dataLayer || []

    // Don't hate me for using window here
    window.queuedProductImpressions = window.queuedProductImpressions || []
  },
  methods: {
    gtm_pushToDataLayer (data) {
      const growthbookUniqueId = document.cookie.match(/growthbook-unique-id=([^&|;]+)/)?.[1] ?? 'no-growthbook-unique-id'
      const baseUrl = new URL('/', document.location.href).toString()
      const event = {
        ...data,
        baseUrl,
        language: getLanguageCode(this.currentLanguageCode),
        country: this.currentCountry.name,
        growthbookUniqueId
      }

      // // Clear the previous datalayer object, to prevent data bleed and/or
      // // it growing too large in size
      // window.dataLayer.push(function () {
      //   this.reset()
      // })

      window.dataLayer.push(event)
    },

    gtm_trackPage (pageType, pageName) {
      pageName = pageName || pageType
      const pageReferrer = window._previouseRoute || document.referrer
      let triggerType = 'hard'
      if (pageReferrer.startsWith(window.location.origin) && pageReferrer !== window.location.href) {
        triggerType = 'soft'
      }

      const payload = {
        event: 'pageView',
        pageInfo: {
          url: window.location.href,
          canonicalUrl: window.location.origin + window.location.pathname,
          pagePath: window.location.pathname,
          pageTitle: pageName,
          pageType,
          pageReferrer,
          countryCode: this.currentCountryCode,
          language: `${this.currentLanguageCode}-${this.currentCountryCode}`,
          currency: this.pricelist.currency.currency,
          triggerType,
          country: this.currentCountry.name
        },
        environmentInfo: {
          isProduction: this.$config.applicationEnv === 'production'
        }
      }

      if (this.$store.state?.account?.account?.isLoggedIn) {
        // How to make this work on initial page load since this fires before
        // the account xhr has finished loading?
        const { city, country, email, firstName, lastName, phoneNumber, zipCode } = this.$store.state.account.account
        payload.userId = this.$store.state.account.account.customer
        payload.user = {
          city,
          country,
          email,
          firstName,
          lastName,
          phoneNumber,
          zipCode
        }
      }

      this.gtm_pushToDataLayer(payload)
      window._previouseRoute = payload.pageInfo.canonicalUrl

      // Push growthbook *after* pageview, but only once
      if (growthbookEventPushed === false) {
        growthbookEventPushed = true
        const viewedExperiments = this.$store.getters['growthbook/gbViewedExperiments']
        if (viewedExperiments) {
          viewedExperiments.forEach(experiment => this.gtm_growthbookExperimentViewed(experiment.experimentId, experiment.variationId))
          if (this.$route.query[gbViewedExperimentsParamName]) {
            // Remove gb-ve from query string, so we don't trigger this tracking again
            this.$router.replace({ query: { ...this.$route.query, [gbViewedExperimentsParamName]: undefined } })
          }
        }
      }
    },

    gtm_trackProductClick (list, product, position) {
      const payload = {
        event: 'productClick',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          click: {
            actionField: {
              list,
            },
            products: [{
              name: product?.name,
              id: product?.sku,
              price: parseFloat(product?.prices?.[this?.pricelist?.pricelist]?.priceAsNumber),
              brand: product?.brandName,
              category: product?.product_type,
              variant: gtmReturnVariantSku(product?.sku, product),
              position
            }]
          }
        }
      }
      this.gtm_pushToDataLayer(payload)
    },

    async gtm_trackProductDetail (product) {
      const payload = {
        event: 'productDetail',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          detail: {
            products: [
              {
                name: product.productSku,
                title: product.name,
                image: product.mainImage,
                id: product.sku,
                parentId: product.productSku,
                brand: product.brandName,
                category: product.product_type,
                price: parseFloat(product.priceAsNumber),
              }
            ]
          }
        }
      }

      if (this.$store.state.gtm) {
        const pushedClick = await this.$store.dispatch('gtm/popLastPushedClick')
        if (pushedClick) {
          const list = pushedClick?.ecommerce?.click?.actionField?.list
          if (list) {
            payload.ecommerce.detail.actionField = { list }
          }
        }
      }

      this.gtm_pushToDataLayer(payload)
    },

    gtm_trackProductImpression (impressions) {
      this.gtm_pushToDataLayer({
        event: 'productImpressions',
        ecommerce: {
          currencyCode: this.pricelist.currency.currency,
          impressions
        }
      })
    },

    gtm_trackFeatureToggleClick (eventName, products, toggleValue) {
      this.gtm_pushToDataLayer({
        event: 'feature_toggle_click',
        ecommerce: {
          featureToggleClick: {
            products,
            toggleValue,
            toggleName: eventName
          }
        }
      })
    },

    /**
     * We queue impressions to 20 and flush them either when number 20 is
     * pushed or after the queue has existed for 0.5 seconds. The reason for
     * this is that you can push too much data by mistake into GTM and we
     * don't want to do that, while we also want to push the separate product
     * as they appear in the browser window
     */
    gtm_queueProductImpression (product, list, position) {
      try {
        window.queuedProductImpressions.push({
          id: product.custom_fields.centra_product_sku?.[0],
          parentId: product.custom_fields.centra_sku?.[0],
          name: product.title,
          price: parseFloat(product.price[0]),
          category: product.custom_fields.product_type?.[0],
          list,
          position,
        })
        const flushImpressionQueue = () => {
          this.gtm_trackProductImpression(window.queuedProductImpressions)
          window.queuedProductImpressions = []
        }
        clearTimeout(window.impressionPushTimer)
        if (window.queuedProductImpressions.length >= 20) {
          flushImpressionQueue()
        } else {
          window.impressionPushTimer = setTimeout(flushImpressionQueue, 500)
        }
      } catch (e) {
        console.error('gtm_queueProductImpression failed')
        console.error(e)
      }
    },
    gtm_trackSelectSize (product, selectedSize) {
      try {
        this.gtm_pushToDataLayer({
          event: 'select_size',
          eventLabel: product.name,
          ecommerce: {
            selectSize: {
              selectedSize,
              productSku: gtmReturnIdProduct(product, selectedSize),
            },
          }
        })
      } catch (error) {
        logError('gtm_trackSelectSize failed: ', error)
      }
    },
    gtm_trackAddToCart (item, quantity, cart) {
      try {
        this.gtm_pushToDataLayer({
          event: 'addToCart',
          eventLabel: item.productName,
          ecommerce: {
            currencyCode: this.pricelist.currency.currency,
            add: {
              products: [
                {
                  name: item._product.productSku,
                  id: gtmReturnId(item),
                  parentId: item._product.productSku,
                  productId: item._product.sku,
                  price: parseFloat(item.priceEachAsNumber),
                  category: item._product.product_type,
                  brand: item.brandName,
                  variant: gtmReturnVariantSku(item._product.sku, item),
                  quantity: quantity || item.quantity,
                  image: item._product.mainImage,
                  title: item._product.name,
                  url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
                }
              ]
            },
            cartItems: {
              products: cart.items.map(item => ({
                name: item._product.productSku,
                id: gtmReturnId(item),
                parentId: item._product.productSku,
                productId: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                variant: gtmReturnVariantSku(item._product.sku, item),
                quantity: item.quantity,
                image: item._product.mainImage,
                title: item._product.name,
                url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
              }))
            },
            order: {
              totalWithShipping: cart.totals.grandTotalPriceAsNumber,
              totalWithoutShipping: cart.totals.itemsTotalPriceAsNumber + cart.totals.totalDiscountPriceAsNumber,
              checkoutUrl: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/checkout'
            }
          }
        })
      } catch (error) {
        logError(error, 'gtm_trackAddToCart failed: ')
      }
    },

    gtm_trackRemoveFromCart (item, quantity, cart) {
      const products = cart.items.map(item => ({
        name: item._product.productSku,
        id: gtmReturnId(item),
        parentId: item._product.productSku,
        productId: item._product.sku,
        price: parseFloat(item.priceEachAsNumber),
        category: item._product.product_type,
        brand: item.brandName,
        variant: gtmReturnVariantSku(item._product.sku, item),
        quantity: item.quantity,
        image: item._product.mainImage,
        title: item._product.name,
        url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
      }))
      if (item.quantity === 0) {
        products.push({
          name: item._product.productSku,
          id: gtmReturnId(item),
          parentId: item._product.productSku,
          productId: item._product.sku,
          price: parseFloat(item.priceEachAsNumber),
          category: item._product.product_type,
          brand: item.brandName,
          variant: gtmReturnVariantSku(item._product.sku, item),
          quantity: 0,
          image: item._product.mainImage,
          title: item._product.name,
          url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
        })
      }
      try {
        this.gtm_pushToDataLayer({
          event: 'removeFromCart',
          eventLabel: item.productName,
          ecommerce: {
            currencyCode: this.pricelist.currency.currency,
            remove: {
              products: [
                {
                  name: item._product.productSku,
                  id: gtmReturnId(item),
                  parentId: item._product.productSku,
                  price: parseFloat(item.priceEachAsNumber),
                  category: item._product.product_type,
                  brand: item.brandName,
                  variant: gtmReturnVariantSku(item._product.sku, item),
                  quantity,
                }
              ]
            },
            cartItems: {
              products
            },
          }
        })
      } catch (error) {
        logError(error, 'gtm_trackRemoveFromCart failed: ')
      }
    },

    gtm_trackCartView (cart) {
      try {
        this.gtm_pushToDataLayer({
          event: 'cartView',
          ecommerce: {
            currencyCode: this.pricelist.currency.currency,
            cartItems: {
              products: cart.items.map(item => ({
                name: item._product.productSku,
                id: gtmReturnId(item),
                parentId: item._product.productSku,
                productId: item._product.sku,
                price: parseFloat(item.priceEachAsNumber),
                category: item._product.product_type,
                brand: item.brandName,
                variant: gtmReturnVariantSku(item._product.sku, item),
                quantity: item.quantity,
                image: item._product.mainImage,
                title: item._product.name,
                url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
              })),
              order: {
                totalWithShipping: cart.totals.grandTotalPriceAsNumber,
                totalWithoutShipping: cart.totals.itemsTotalPriceAsNumber + cart.totals.totalDiscountPriceAsNumber,
                checkoutUrl: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/checkout'
              }
            }
          }
        })
      } catch (error) {
        logError(error, 'gtm_trackCartView failed: ')
      }
    },
    gtm_trackCheckoutStart (cart) {
      return this.gtm_trackCheckoutStep(cart, 'start', 'checkout')
    },
    gtm_trackPLPLoaded () {
      this.gtm_pushToDataLayer({ event: 'plpLoaded' })
    },
    gtm_trackCheckoutflow (payload) {
      this.gtm_pushToDataLayer({
        event: 'checkoutFlow',
        testecommerce: payload
      })
    },

    gtm_trackCheckoutStep (cart, step, event) {
      if (!cart) {
        return
      }
      event = event || 'checkoutOption'
      this.gtm_pushToDataLayer({
        event,
        ecommerce: {
          cartGrandTotal: cart.totals.grandTotalPrice,
          currencyCode: this.pricelist.currency.currency,
          checkout: {
            actionField: { step },
            products: cart.items.map(item => ({
              name: item._product.productSku,
              id: gtmReturnId(item),
              parentId: item._product.productSku,
              productId: item._product.sku,
              price: parseFloat(item.priceEachAsNumber),
              category: item._product.product_type,
              brand: item.brandName,
              variant: gtmReturnVariantSku(item._product.sku, item), // updating this to contain colour code despite history of using size
              quantity: item.quantity,
              image: item._product.mainImage,
              title: item._product.name,
              url: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/' + item._product.url
            }))
          },
          order: {
            order_id: cart.selection + Date.now(),
            totalWithShipping: cart.totals.grandTotalPriceAsNumber,
            totalWithoutShipping: cart.totals.itemsTotalPriceAsNumber + cart.totals.totalDiscountPriceAsNumber,
            checkoutUrl: 'https://www.' + this.market.name + '/' + this.currentCountryCode.toLowerCase() + '/checkout'
          }
        }
      })
    },

    gtm_trackSearch (searchQuery) {
      this.gtm_pushToDataLayer({
        event: 'search',
        searchQuery
      })
    },

    gtm_trackPurchase (order) {
      const orderTrackCookieName = `orderTrack_${order.order}`
      if (this.$cookies.get(orderTrackCookieName)) {
        console.warn(`Order ${order.order} purchase already tracked`)
        return
      }

      // Use cookie to prevent double tracking
      this.$cookies.set(orderTrackCookieName, 1, {
        maxAge: 365 * 24 * 60 * 60,
      })

      let coupon = ''
      if (order?.discounts?.vouchers) {
        coupon = Object.values(order.discounts.vouchers)
          .map(voucher => `${voucher.description} (${voucher.voucher})`)
          .join(', ')
      }

      const isCorrectCity = this.countrySpecificInformation.content?.TrustedShops === order.address.city
      const sendToTrustedShops = this.currentCountry.country === 'DE' && isCorrectCity

      // const productRevenue = Object.keys(this.countrySpecificInformation.content?.FB_Meta_Pixel[0])
      //   .reduce((acc, key) => {
      //     const productRevenue = key.split('_')[1]
      //     acc[productRevenue] = this.countrySpecificInformation.content?.FB_Meta_Pixel[0][key]
      //     return acc
      //   }, {})

      const REVNUE_RATIO = {
        SE: 1.021,
        FI: 1.055,
        ES: 1.094,
        NL: 0.918,
        FR: 1.141,
        BE: 1.102,
        IE: 1.029,
        DE: 0.912,
        AT: 1.039,
        US: 1.092,
        CA: 1.222,
        AU: 1.166,
        IT: 1.163,
        NZ: 1.155
      }
      const fbRrevenue = !REVNUE_RATIO[order.address.country] ? parseFloat(order.totals.grandTotalPriceAsNumber) : parseFloat(REVNUE_RATIO[order.address.country] * order.totals.grandTotalPriceAsNumber).toFixed(2)
      const postalCode = order.address.zipCode ?? ''

      this.gtm_pushToDataLayer({
        event: 'mm_purchase',
        mmec: {
          currencyCode: order.currency,
          purchase: {
            actionField: {
              hashedEmail: order.address?.hashedEmail,
              hashedFirstname: order.address?.hashedFirstname,
              hashedLastname: order.address?.hashedLastname,
              em: order.address?.email,
              fn: order.address?.firstName,
              ln: order.address?.lastName,
              ph: order.address?.phoneNumber,
              id: order.order, // Transaction ID. Required for purchases and refunds.
              affiliation: this.market.name,
              revenue: parseFloat(order.totals.grandTotalPriceAsNumber), // Total transaction value (incl. tax and shipping)
              tax: parseFloat(order.totals.grandTotalPriceTaxAsNumber),
              shipping: parseFloat(order.totals.shippingPriceAsNumber),
              revenueSubtotal: parseFloat((order.totals.grandTotalPriceAsNumber - order.totals.grandTotalPriceTaxAsNumber - order.totals.shippingPriceAsNumber)),
              coupon,
              sendToTrustedShops,
              fbRrevenue,
              postalCode,
              country: order.address?.country,
              city: order.address?.city,
              hashedCountry: order.address?.hashedCountry,
              hashedCity: order.address?.hashedCity,
              hashedZip: order.address?.hashedZip,
              hashedPhoneNumber: order.address?.hashedPhone,
              customerId: order.customer,
              paymentMethod: order.paymentMethod,
            },
            products: order.items.map((item) => {
              const multiplier = 1
              const price = parseFloat(item.priceEachAsNumber)
              return {
                id: gtmReturnId(item),
                parentId: item._product.productSku,
                name: item._product.productSku,
                sku: item.sku,
                price: parseFloat(item.priceEachAsNumber),
                variant: gtmReturnVariantSku(item._product.sku, item),
                brand: item.brandName,
                quantity: item.quantity,
                category: item._product.product_type,
                productRevenue: price * multiplier
              }
            })
          }
        }
      })
    },

    gtm_growthbookExperimentViewed (experimentId, variationId) {
      console.debug('gtm_growthbookExperimentViewed: ', experimentId, variationId)
      this.gtm_pushToDataLayer({
        event: 'growthbookExperimentViewed',
        growthbook: {
          experimentId,
          variationId
        }
      })
    },

    gtm_trackQuickAddToCartClick (product) {
      console.debug('gtm_trackQuickAddToCartClick: ', product.sku)
      this.gtm_pushToDataLayer({
        event: 'quickshopAddToCartClick',
        ecommerce: {
          currencyCode: this.pricelist?.currency?.currency,
          affiliation: this.market?.name,
          click: {
            product: {
              name: product?.name,
              variantName: product?.variantName,
              variant: gtmReturnVariantSku(product?.sku, product),
              sku: product?.sku,
              price: product?.price,
            }
          }
        }
      })
    }
  }
})
