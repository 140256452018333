import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=793b569c&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=793b569c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793b569c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WashingsWarranty: require('/usr/src/app/components/washings-warranty/WashingsWarranty.vue').default,FooterMenu: require('/usr/src/app/components/footer/FooterMenu.vue').default,MpLink: require('/usr/src/app/components/mp-link/MpLink.vue').default,TryggEhandel: require('/usr/src/app/components/trygg-ehandel/TryggEhandel.vue').default,TrustpilotWidget: require('/usr/src/app/components/trustpilot/TrustpilotWidget.vue').default,PaymentGatewayLogos: require('/usr/src/app/components/payment-gateway-logos/PaymentGatewayLogos.vue').default,Footer: require('/usr/src/app/components/footer/Footer.vue').default})
